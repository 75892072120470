import React from "react";

const Flow = () => {
	const step = [
		{
			id: 1,
			desc: "〇〇〇〇〇〇〇〇",
		},
		{
			id: 2,
			desc: "〇〇〇〇〇〇〇〇",
		},
		{
			id: 3,
			desc: "〇〇〇〇〇〇〇〇",
		},
		{
			id: 4,
			desc: "〇〇〇〇〇〇〇〇",
		},
		{
			id: 5,
			desc: "〇〇〇〇〇〇〇〇",
		},
	];
	return (
		<div className="relative lg:w-1/2">
			<span className="block border-r-2 border-accent absolute h-full top-0 left-3"></span>
			<ul>
				{step.map((s) => {
					return (
						<li className="mb-8" key={s.id}>
							<div className="flex items-center mb-2">
								<span className="block bg-accent rounded-full h-6 w-6"></span>
								<p className="text-sm tracking-wider flex-1 ml-4 font-bold">
									STEP<span className="text-accent ml-1">{s.id}</span>
								</p>
							</div>
							<p className="ml-10 lg:text-lg">{s.desc}</p>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Flow;
