import React from "react";

const Section = (props) => {
	return (
		<section id={props.id}>
			<div className="max-w-6xl mx-auto mb-20 lg:mb-32">
				<h2
					style={{ width: `100vw`, margin: `0 calc(50% - 50vw) 16px` }}
					className="title-heading"
					data-en={props.en}
				>
					{props.title}
				</h2>
				{props.children}
			</div>
		</section>
	);
};

export default Section;
