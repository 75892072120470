import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/outline";

const Faq = () => {
	const faq = [
		{
			id: 1,
			q: "質問 1",
			a: "回答 1",
		},
		{
			id: 2,
			q: "質問 2",
			a: "回答 2",
		},
		{
			id: 3,
			q: "質問 3",
			a: "回答 3",
		},
		{
			id: 4,
			q: "質問 4",
			a: "回答 4",
		},
		{
			id: 5,
			q: "質問 5",
			a: "回答 5",
		},
	];
	return (
		<>
			{faq.map((f) => {
				return (
					<Disclosure key={f.id}>
						{({ open }) => (
							<>
								<Disclosure.Button className="flex items-center justify-between p-4 bg-gray-50 border-b w-full lg:text-lg">
									<h3>Q {f.q}</h3>
									<ChevronRightIcon
										className={`inline-block w-4 h-4 lg:w-6 lg:h-6 text-accent ${
											open ? "transform rotate-90" : ""
										}`}
									/>
								</Disclosure.Button>
								<Transition
									enter="transition duration-300 ease-out"
									enterFrom="transform scale-95 opacity-0"
									enterTo="transform scale-100 opacity-100"
									leave="transition duration-300 ease-out"
									leaveFrom="transform scale-100 opacity-100"
									leaveTo="transform scale-95 opacity-0"
								>
									<Disclosure.Panel className="p-4">
										<span className="text-accent lg:text-lg">A</span> {f.a}
									</Disclosure.Panel>
								</Transition>
							</>
						)}
					</Disclosure>
				);
			})}
		</>
	);
};

export default Faq;
