import React from "react";
import {
	PhoneIcon,
	MailIcon,
	LocationMarkerIcon,
} from "@heroicons/react/outline";

const Contact = () => {
	const contactMenu = [
		{
			id: 1,
			icon: (
				<PhoneIcon className="text-accent w-5 h-5 inline-block align-text-top" />
			),
			way: "Tel",
			link: "tel:0123-456-789",
			linkText: "0123-456-789",
		},
		{
			id: 2,
			icon: (
				<MailIcon className="text-accent w-5 h-5 inline-block align-text-top" />
			),
			way: "Mail",
			link: "mailto:hello@eandbe.com",
			linkText: "hello@eandbe.com",
		},
		{
			id: 3,
			icon: (
				<LocationMarkerIcon className="text-accent w-5 h-5 inline-block align-text-top" />
			),
			way: "Location",
			link: "https://goo.gl/maps/DPpQJVjHRHWk163a7",
			linkText: "沖縄県浦添市〇〇〇〇〇〇",
		},
	];
	return (
		<div className="md:flex md:mb-8 lg:justify-between">
			<ul className="md:w-1/3">
				{contactMenu.map((menu) => {
					return (
						<li className="mb-6 lg:mb-12" key={menu.id}>
							<div className="flex">
								<span className="mr-4">{menu.icon}</span>
								<div className="flex-1">
									<p className="font-bold mb-2">{menu.way}</p>
									<a href={menu.link}>{menu.linkText}</a>
								</div>
							</div>
						</li>
					);
				})}
			</ul>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14313.402808903584!2d127.747068!3d26.250279!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e56cf4fb27e103%3A0x20573d4476f35acc!2z44CSOTAxLTIxMDEg5rKW57iE55yM5rWm5re75biC6KW_5Y6f77yU5LiB55uu77yT77yT4oiS77yW!5e0!3m2!1sja!2sjp!4v1628420824773!5m2!1sja!2sjp"
				className="border-0 w-full h-auto mb-6 md:w-2/3 lg:h-[450px] lg:w-[600px]"
				title="地図"
				allowFullScreen=""
				loading="lazy"
			></iframe>
		</div>
	);
};

export default Contact;
